@import '~swiper/css/swiper.css';

.swiper-button::after {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

@media (min-width: 768px){
  .swiper-button::after{
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

.swiper-pagination-bullet {
  margin-top: 1rem;
  margin-left: 0.5rem;
  height: 0.5rem;
  width: 0.5rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  opacity: 1;
}

@media (min-width: 768px){
  .swiper-pagination-bullet{
    height: 1rem;
    width: 1rem;
  }
}

.swiper-pagination-bullet:hover {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.swiper-pagination-bullet-active {
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}

.swiper-pagination-bullet-active:hover {
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}

.swiper-button-prev {
  margin-right: 2rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.swiper-button-next {
  margin-left: 2rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}