/* BUTTONS */
.btn {
    @apply flex rounded-full text-white text-center cursor-pointer;
}

/* Colors */
.btn.btn-green {
    @apply bg-green-700 text-white no-underline;
}

.btn.btn-green:hover {
    @apply bg-blue-700 text-white no-underline;
}

.btn.btn-green:active {
    @apply bg-red-700 text-white no-underline;
}

/* Sizes */
.btn.btn-xs {
    @apply px-4 py-2;
}
.btn.btn-sm, .btn.btn-s {
    @apply text-lg px-6 py-2;
}
.btn.btn-md, .btn.btn-m {
    @apply w-2/6 text-lg p-3;
}
.btn.btn-lg, .btn.btn-l {
    @apply w-3/6 text-lg p-4;
}
.btn.btn-xl {
    @apply w-4/6 text-2xl p-5;
}

button#speaker > span > img {
    width: 1.25em;
    vertical-align: -0.225em;
    text-align: center;
    font-size: 1.3333333333em;
    line-height: 0.75em;
    display: inline-block;
    height: 1em;
    overflow: visible;
}
