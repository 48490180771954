
/* @import '@/Styles/Tools/_breakpoints.scss';

.container, .container-fluid, .container-full {
    &[class*="--background"] {
        padding-top: $gutter / 2;
        padding-bottom: $gutter / 2;

        @include media-md {
            padding-top: $gutter;
            padding-bottom: $gutter;
        }
    }
    &--background-green {
        background-color: $green;
    }
}

.container > .container-fluid, .container-fluid > .container, .container > .container, .container-fluid > .container-fluid {
    padding-right: 0;
    padding-left: 0;
}
@include media-sm {
    .container > .container-fluid, .container-fluid > .container, .container > .container, .container-fluid > .container-fluid {
        padding-right: 15px;
        padding-left: 15px;
    }
} */
